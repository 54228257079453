import axios from "axios";
import { getAuthToken } from "../Search/utils";
import { GetNetProfit, GetRevenue } from "./query";

interface AggregationData {
  lastWeekTotal: number;
  dailyData: { date: string; total: number }[];
  previousWeekTotal: number;
}

export const getRevenueData = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta"
): Promise<AggregationData> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  try {
    const response = await axios.post(searchUrl, GetRevenue, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const { last_week, previous_week } = response.data.aggregations;

    return {
      lastWeekTotal: last_week.weekly_total.value,
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.daily_total.value,
      })),
      previousWeekTotal: previous_week.weekly_total.value,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getOrdersData = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta"
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const ReqQuery = {
    size: 0,
    aggs: {
      last_week: {
        filter: {
          bool: {
            must: [
              {
                range: {
                  createdDate: {
                    gte: "now-7d/d",
                    lt: "now",
                  },
                },
              },
              {
                term: {
                  "storeId.keyword": storeId, //storeId
                },
              },
              {
                term: {
                  "driverStatus.keyword": "Completed",
                },
              },
            ],
          },
        },
        aggs: {
          total_amount: {
            sum: {
              field: "total",
            },
          },
          daily_data: {
            date_histogram: {
              field: "createdDate",
              interval: "day",
              min_doc_count: 0,
            },
            aggs: {
              daily_total: {
                sum: {
                  field: "total",
                },
              },
            },
          },
        },
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, ReqQuery, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    const { last_week } = response.data?.aggregations;
    return {
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.doc_count,
      })),
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getNetProfit = async (
  indexName: string,
  storeId: string | null,
  timeZone: string = "Asia/Calcutta"
): Promise<AggregationData> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  try {
    const response = await axios.post(searchUrl, GetNetProfit, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const { last_week, previous_week } = response.data.aggregations;

    return {
      lastWeekTotal: last_week.weekly_total.value,
      dailyData: last_week.daily_data.buckets.map((bucket: any) => ({
        date: bucket.key_as_string,
        total: bucket.daily_total.value,
      })),
      previousWeekTotal: previous_week.weekly_total.value,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

interface ProductData {
  itemName: string;
  imageURL?: string;
  sellingPriceInclPrice2: string;
  trendingProduct: number;
}

interface TrendingProductsResponse {
  products: ProductData[];
}

export const getTrendingProducts =
  async (): Promise<TrendingProductsResponse> => {
    const indexName = "product";
    let index = process.env.REACT_APP_STACK
      ? `${indexName}${process.env.REACT_APP_STACK}`
      : indexName;

    const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

    try {
      const response = await axios.post(
        searchUrl,
        {
          size: 20,
          query: {
            match_all: {},
          },
          sort: [
            {
              trendingProduct: {
                order: "desc",
              },
            },
          ],
          _source: [
            "itemName",
            "imageURL",
            "extraFieldsProduct.sellingPriceInclPrice2",
            "trendingProduct",
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAuthToken(),
          },
        }
      );

      const products = response.data.hits.hits.map((hit: any) => ({
        itemName: hit._source.itemName,
        imageURL: hit._source.imageURL,
        sellingPriceInclPrice2:
          hit._source.extraFieldsProduct.sellingPriceInclPrice2,
        trendingProduct: hit._source.trendingProduct,
      }));

      return {
        products,
      };
    } catch (error) {
      console.error("Error performing search query:", error);
      throw error;
    }
  };

export const getSalesStatistics = async (
  indexName: string,
  calendarInterval: string,
  timeZone: string = "Asia/Calcutta",
  orderId: string | null
): Promise<AggregationData> => {
  const index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const requestBody = {
    aggs: {
      order: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: calendarInterval,
          time_zone: "Asia/Calcutta",
          min_doc_count: 1,
        },
        aggs: {
          total: {
            sum: {
              field: "total",
            },
          },
        },
      },
    },
    size: 0,
    stored_fields: ["*"],
    script_fields: {},
    docvalue_fields: [
      {
        field: "actionLog.timestamp",
        format: "date_time",
      },
      {
        field: "createdDate",
        format: "date_time",
      },
      {
        field: "driverDetails.deliveryDate",
        format: "date_time",
      },
      {
        field: "driverDetails.expireTime",
        format: "date_time",
      },
      {
        field: "otpCreationTime",
        format: "date_time",
      },
      {
        field: "updatedDate",
        format: "date_time",
      },
    ],
    _source: {
      excludes: [],
    },
    query: {
      bool: {
        must: [],
        filter: [
          {
            term: {
              "storeId.keyword": orderId,
            },
          },
          {
            term: {
              "driverStatus.keyword": "Completed",
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const buckets = response.data.aggregations.order.buckets;

    const dailyData = buckets.map((bucket: any) => ({
      date: bucket.key_as_string,
      total: bucket.total.value || 0,
    }));

    const lastWeekTotal = buckets.reduce(
      (acc: number, bucket: any) => acc + (bucket.total.value || 0),
      0
    );

    return {
      lastWeekTotal,
      dailyData,
      previousWeekTotal: 0, // Modify if you have a way to calculate the previous week's total
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

//==========================API CHANGES==========================
interface ProductStock {
  SK: string;
  sellQuantity: number;
}

interface CombinedProductData {
  SK: string;
  sellQuantity: number;
  itemName: string;
  salesPrice: number;
  costPrice: number;
  imageURL: string;
}

export const getProductStock = async (
  productType: string,
  storeId: string
): Promise<ProductStock[]> => {
  const indexName = "productstock";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;

  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 20,
    query: {
      bool: {
        must: [
          {
            term: {
              "productType.keyword": productType,
            },
          },
          {
            term: {
              "storeId.keyword": storeId,
            },
          },
          {
            range: {
              sellQuantity: {
                gt: 0,
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        sellQuantity: {
          order: "desc",
        },
      },
    ],
    _source: ["productCode", "sellQuantity", "SK"],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.hits.hits.map((hit: any) => hit._source);
};

export const getProductDetails = async (SK: string): Promise<any> => {
  const indexName = "product";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    query: {
      term: {
        "SK.keyword": SK,
      },
    },
    _source: [
      "itemName",
      "sku",
      "extraFieldsProduct.salesPrice",
      "extraFieldsProduct.costPrice",
      "imageURL",
    ],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  const source = response.data.hits.hits[0]?._source;
  return {
    SK: source?.SK,
    sku: source?.sku,
    itemName: source?.itemName,
    salesPrice: source?.extraFieldsProduct?.salesPrice,
    costPrice: source?.extraFieldsProduct?.costPrice,
    imageURL: source?.imageURL,
  };
};

export const getCombinedProductData = async (
  productType: string,
  storeId: string | null
): Promise<CombinedProductData[]> => {
  const productStock = await getProductStock(
    productType,
    storeId ? storeId : ""
  );
  const combinedData = await Promise.all(
    productStock.map(async (stock) => {
      const details = await getProductDetails(stock.SK);
      return {
        SK: stock.SK,
        sku: details.sku,
        sellQuantity: stock.sellQuantity,
        itemName: details.itemName,
        salesPrice: details.salesPrice,
        costPrice: details.costPrice,
        imageURL: details.imageURL,
      };
    })
  );

  return combinedData;
};

export const getTotalSales = async (storeId: string | null): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { term: { "storeId.keyword": storeId } },
          { term: { "orderStatus.keyword": "Completed" } },
          {
            range: {
              createdDate: {
                gte: "now-30d/d",
                lte: "now/d",
              },
            },
          },
        ],
      },
    },
    aggs: {
      daily_sales: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
        },
        aggs: {
          total_sales: {
            sum: { field: "subtotal" },
          },
        },
      },
      sales_summary: {
        filters: {
          filters: {
            daily: { range: { createdDate: { gte: "now/d", lte: "now/d" } } },
            current_week: {
              range: { createdDate: { gte: "now/w", lte: "now/d" } },
            },
            current_month: {
              range: { createdDate: { gte: "now/M", lte: "now/d" } },
            },
          },
        },
        aggs: {
          total_sales: { sum: { field: "subtotal" } },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.sales_summary.buckets;
};

export const getTotalOrders = async (storeId: string | null): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { term: { "storeId.keyword": storeId } },
          { term: { "orderStatus.keyword": "Completed" } },
          {
            range: {
              createdDate: {
                gte: "now-30d/d",
                lte: "now/d",
              },
            },
          },
        ],
      },
    },
    aggs: {
      total_orders: {
        filters: {
          filters: {
            daily: {
              range: {
                createdDate: {
                  gte: "now/d",
                  lte: "now/d",
                },
              },
            },
            current_week: {
              range: {
                createdDate: {
                  gte: "now/w",
                  lte: "now/d",
                },
              },
            },
            current_month: {
              range: {
                createdDate: {
                  gte: "now/M",
                  lte: "now/d",
                },
              },
            },
          },
        },
        aggs: {
          order_count: {
            value_count: {
              field: "id.keyword",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.total_orders.buckets;
};

export const getAverageOrderValue = async (
  storeId: string | null
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            term: {
              "storeId.keyword": storeId,
            },
          },
          {
            term: {
              "orderStatus.keyword": "Completed",
            },
          },
        ],
      },
    },
    aggs: {
      current_day: {
        filter: {
          range: {
            createdDate: {
              gte: "now/d",
              lt: "now+1d/d",
            },
          },
        },
        aggs: {
          order_count: {
            value_count: {
              field: "id.keyword",
            },
          },
          total_order_value_sum: {
            sum: {
              field: "total",
            },
          },
        },
      },
      current_week: {
        filter: {
          range: {
            createdDate: {
              gte: "now/w",
              lt: "now+1w/w",
            },
          },
        },
        aggs: {
          order_count: {
            value_count: {
              field: "id.keyword",
            },
          },
          total_order_value_sum: {
            sum: {
              field: "total",
            },
          },
        },
      },
      current_month: {
        filter: {
          range: {
            createdDate: {
              gte: "now/M",
              lt: "now+1M/M",
            },
          },
        },
        aggs: {
          order_count: {
            value_count: {
              field: "id.keyword",
            },
          },
          total_order_value_sum: {
            sum: {
              field: "total",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations;
};

export const getErrorsPerOrderGOR = async (
  storeId: string | null
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [{ term: { "storeId.keyword": storeId } }],
      },
    },
    aggs: {
      completed_orders: {
        filter: {
          term: { "orderStatus.keyword": "Completed" },
        },
        aggs: {
          current_day: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/d",
                  lt: "now+1d/d",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
          current_week: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/w",
                  lt: "now+1w/w",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
          current_month: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/M",
                  lt: "now+1M/M",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
        },
      },
      all_orders: {
        filter: {
          terms: { "orderStatus.keyword": ["Completed", "Pending"] },
        },
        aggs: {
          current_day: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/d",
                  lt: "now+1d/d",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
          current_week: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/w",
                  lt: "now+1w/w",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
          current_month: {
            filter: {
              range: {
                createdDate: {
                  gte: "now/M",
                  lt: "now+1M/M",
                },
              },
            },
            aggs: {
              order_count: { value_count: { field: "id.keyword" } },
              order_value: { sum: { field: "total" } },
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations;
};

export const getTotalSalesOrderReports = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { term: { "storeId.keyword": storeId } },
          { term: { "orderStatus.keyword": "Completed" } },
          {
            range: {
              createdDate: {
                gte: startDate,
                lt: endDate,
              },
            },
          },
        ],
      },
    },
    aggs: {
      daily_metrics: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
          format: "yyyy-MM-dd",
          min_doc_count: 0,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "total",
            },
          },
          total_orders: {
            value_count: {
              field: "id.keyword",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response?.data?.aggregations?.daily_metrics?.buckets || [];
};

export const getTopSellingProductByCategory = async (
  storeId: string | null
): Promise<any> => {
  const indexName = "order";
  const categoryIndexName = "category";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  let categoryIndex = process.env.REACT_APP_STACK
    ? `${categoryIndexName}${process.env.REACT_APP_STACK}`
    : categoryIndexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const categorySearchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${categoryIndex}/_search`;

  const query = {
    query: {
      bool: {
        filter: [
          { term: { "storeId.keyword": storeId } },
          { term: { "orderStatus.keyword": "Completed" } },
        ],
      },
    },
    aggs: {
      top_selling_products: {
        terms: {
          field: "orderDetails.productName.keyword",
          size: 10,
          order: { total_units_sold: "desc" },
        },
        aggs: {
          category: {
            terms: {
              field: "orderDetails.categorySK.keyword",
              size: 1,
            },
          },
          total_sales: { sum: { field: "orderDetails.salesPrice" } },
          total_units_sold: { sum: { field: "orderDetails.quantity" } },
        },
      },
    },
  };

  const response1 = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  // Step 2: Extract category SKs from the first query result
  const categorySKs =
    response1.data.aggregations.top_selling_products.buckets.map(
      (bucket: { category: { buckets: { key: any }[] } }) => {
        return bucket.category.buckets[0].key; // Get the categorySK of the product
      }
    );

  const query2 = {
    query: {
      terms: {
        "id.keyword": categorySKs, // Pass the extracted category SKs into the query
      },
    },
  };
  // Step 3: Execute the second query (Category Data) with the extracted category SKs
  const response2 = await axios.post(categorySearchUrl, query2, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  // Step 4: Merge the results based on category SK
  const mergedResults =
    response1.data.aggregations.top_selling_products.buckets.map(
      (product: {
        category: {
          categoryName: any;
          buckets: { key: any }[];
        };
        key: any;
        total_sales: { value: any };
        total_units_sold: { value: any };
      }) => {
        const categoryData = response2.data.hits.hits.find(
          (category: { _source: { id: any } }) =>
            category._source.id === product.category.buckets[0].key
        );

        return {
          ...product,
          category: {
            ...product.category,
            categoryName: categoryData ? categoryData._source.name : "Unknown",
          },
        };
      }
    );

  return mergedResults;
};

export const getSalesByProductCategory = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  const categoryIndexName = "category";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  let categoryIndex = process.env.REACT_APP_STACK
    ? `${categoryIndexName}${process.env.REACT_APP_STACK}`
    : categoryIndexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const categorySearchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${categoryIndex}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { range: { createdDate: { gte: startDate, lte: endDate } } },
          { term: { "storeId.keyword": storeId } },
          { term: { "orderStatus.keyword": "Completed" } },
        ],
      },
    },
    aggs: {
      sales_by_category: {
        terms: {
          field: "orderDetails.categorySK.keyword",
          size: 10,
          order: { total_sales: "desc" },
        },
        aggs: {
          total_sales: {
            sum: {
              field: "orderDetails.salesPrice",
            },
          },
        },
      },
    },
  };

  const response1 = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  const categorySKs = response1.data.aggregations.sales_by_category.buckets.map(
    (bucket: { key: any }) => {
      return bucket.key; // Get the categorySK of the product
    }
  );

  const query2 = {
    query: {
      terms: {
        "id.keyword": categorySKs, // Pass the extracted category SKs into the query
      },
    },
  };
  // Step 3: Execute the second query (Category Data) with the extracted category SKs
  const response2 = await axios.post(categorySearchUrl, query2, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  // Step 4: Merge the results based on category SK
  const mergedResults =
    response1.data.aggregations.sales_by_category.buckets.map(
      (product: { key: any; category: any }) => {
        const categoryData = response2.data.hits.hits.find(
          (category: { _source: { id: any } }) =>
            category._source.id === product.key
        );

        return {
          ...product,
          category: {
            ...product.category,
            categoryName: categoryData ? categoryData._source.name : "Unknown",
          },
        };
      }
    );

  return mergedResults || [];
};

export const getAverageOrderTime = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { range: { createdDate: { gte: startDate, lte: endDate } } },
          { term: { "orderStatus.keyword": "Completed" } },
          { term: { "storeId.keyword": storeId } },
        ],
      },
    },
    aggs: {
      orders: {
        terms: {
          field: "commonOrderId.keyword",
          size: 100,
        },
        aggs: {
          order_placed_time: {
            min: {
              field: "createdDate",
            },
          },
          order_closed_time: {
            max: {
              field: "endOrderDeliveryTime",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.orders.buckets || [];
};

export const getAverageOrderTimeWithOutDate = async (
  storeId: string | null,
  timeRange: "day" | "week" | "month" = "day"
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const dateHistogramField =
    timeRange === "day"
      ? "createdDate"
      : timeRange === "week"
        ? "createdDate"
        : "createdDate";

  const interval =
    timeRange === "day" ? "day" : timeRange === "week" ? "week" : "month";

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { term: { "orderStatus.keyword": "Completed" } },
          { term: { "storeId.keyword": storeId } },
        ],
      },
    },
    aggs: {
      time_range: {
        date_histogram: {
          field: dateHistogramField,
          calendar_interval: interval,
        },
        aggs: {
          orders: {
            terms: {
              field: "commonOrderId.keyword",
              size: 100,
            },
            aggs: {
              order_placed_time: {
                min: {
                  field: "createdDate",
                },
              },
              order_closed_time: {
                max: {
                  field: "endOrderDeliveryTime",
                },
              },
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.time_range.buckets || [];
};

export const getErrorsPerOrder = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          startDate
            ? {
                range: {
                  createdDate: {
                    gte: startDate,
                    lte: endDate,
                  },
                },
              }
            : null,
          {
            term: {
              "storeId.keyword": storeId,
            },
          },
        ],
      },
    },
    aggs: {
      orders_per_day: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
        },
        aggs: {
          total_orders: {
            value_count: {
              field: "orderStatus.keyword",
            },
          },
          orders_completed: {
            filter: {
              term: {
                "orderStatus.keyword": "Completed",
              },
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.orders_per_day.buckets;
};

export const getHourlySalesTrends = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { range: { createdDate: { gte: startDate, lte: endDate } } },
          { term: { "orderStatus.keyword": "Completed" } },
          { term: { "storeId.keyword": storeId } },
        ],
      },
    },
    aggs: {
      orders_per_hour: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "hour",
          format: "HH:00",
        },
        aggs: {
          total_sales: {
            sum: {
              field: "total",
            },
          },
          total_orders: {
            value_count: {
              field: "orderStatus.keyword",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.orders_per_hour.buckets;
};

export const getRefundsCancellation = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          { range: { createdDate: { gte: startDate, lte: endDate } } },
          { term: { "storeId.keyword": storeId } },
        ],
      },
    },
    aggs: {
      orders_by_date: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
          format: "yyyy-MM-dd",
        },
        aggs: {
          total_refunds: {
            sum: {
              field: "refundOrderDetails.salesPrice",
            },
          },
          total_cancellations: {
            value_count: {
              field: "refundOrderDetails.salesPrice",
            },
          },
          top_refund_reason: {
            terms: {
              field: "orderCloseReason.keyword",
              size: 1,
            },
          },
          total_orders: {
            value_count: {
              field: "commonOrderId.keyword",
            },
          },
        },
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.orders_by_date.buckets;
};

export const getNewVsReturningCustomers = async (
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "user";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            bool: {
              should: [
                {
                  range: {
                    createdDate: {
                      gte: startDate,
                      lte: endDate,
                    },
                  },
                },
                {
                  range: {
                    returningSigningDate: {
                      gte: startDate,
                      lte: endDate,
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    aggs: {
      new_customers_by_date: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
        },
        aggs: {
          new_customer_count: {
            value_count: {
              field: "id.keyword",
            },
          },
        },
      },
      returning_customers_by_date: {
        date_histogram: {
          field: "returningSigningDate",
          calendar_interval: "day",
        },
        aggs: {
          returning_customer_count: {
            value_count: {
              field: "id.keyword",
            },
          },
        },
      },
    },
    _source: ["createdDate", "returningSigningDate", "id"],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations;
};

export const getCustomerPurchaseFrequency = async (
  storeId: string | null,
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    query: {
      bool: {
        filter: [
          {
            range: {
              createdDate: {
                gte: startDate,
                lte: endDate,
              },
            },
          },
          {
            term: {
              storeId: storeId,
            },
          },
        ],
      },
    },
    aggs: {
      customer_purchase_frequency: {
        terms: {
          field: "customerDetails.customerId.keyword",
          size: 1000,
        },
        aggs: {
          total_purchases: {
            value_count: {
              field: "id.keyword",
            },
          },
          customer_name: {
            terms: {
              field: "customerDetails.customerName.keyword",
              size: 1000,
            },
          },
        },
      },
    },
    _source: [
      "customerDetails.customerId",
      "customerDetails.customerName",
      "id",
    ],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.customer_purchase_frequency.buckets;
};

export const getAbandonedCartSummary = async (
  startDate: any,
  endDate: any
): Promise<any> => {
  const indexName = "order";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    query: {
      bool: {
        filter: [
          {
            range: {
              createdDate: {
                gte: startDate,
                lte: endDate,
              },
            },
          },
        ],
      },
    },
    aggs: {
      carts_by_date: {
        date_histogram: {
          field: "createdDate",
          calendar_interval: "day",
        },
        aggs: {
          total_carts: {
            value_count: {
              field: "id.keyword",
            },
          },
          abandoned_carts: {
            filter: {
              term: {
                isDeleted: true,
              },
            },
            aggs: {
              count: {
                value_count: {
                  field: "id.keyword",
                },
              },
            },
          },
        },
      },
    },
    _source: ["createdDate", "id", "isDeleted"],
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.aggregations.carts_by_date.buckets;
};

export const getOutOfStockProducts = async (
  storeId: string | null
): Promise<any> => {
  const indexName = "productstock";
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;

  const query = {
    size: 10000,
    query: {
      bool: {
        must: [
          {
            term: {
              storeId: storeId,
            },
          },
          {
            range: {
              availableQuantity: {
                lt: 5,
              },
            },
          },
        ],
      },
    },
  };

  const response = await axios.post(searchUrl, query, {
    headers: {
      "Content-Type": "application/json",
      Authorization: await getAuthToken(),
    },
  });

  return response.data.hits.hits;
};
