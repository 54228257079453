import { Routers } from "../../Routes/Routes";
import { ReactComponent as Home } from "../../Asset/home.svg";
import { ReactComponent as Categories } from "../../Asset/categori.svg";
import { ReactComponent as Products } from "../../Asset/product.svg";
import { ReactComponent as Orders } from "../../Asset/order.svg";
import { ReactComponent as Customers } from "../../Asset/customers.svg";
import { ReactComponent as Coupons } from "../../Asset/coupons.svg";
import { ReactComponent as Communications } from "../../Asset/communications.svg";
import { ReactComponent as UserCircle } from "../../Asset/user-circle.svg";
import { ReactComponent as CirclePlus } from "../../Asset/circle-plus.svg";
import { ReactComponent as StoreManagement } from "../../Asset/store-management.svg";
import { ReactComponent as Polygon } from "../../Asset/polygon.svg";

const sidebar_menu = [
  {
    menu: "Dashboard",
    submenu: [
      {
        icon: <Home />,
        label: "Dashboard",
        route: Routers?.dashboard,
      },
    ],
  },

  {
    menu: "Catalog",
    submenu: [
      {
        icon: <Categories />,
        label: "Categories",
        route: Routers?.categories,
      },
      {
        icon: <Products />,
        label: "All Products",
        route: Routers?.allproducts,
      },
      {
        icon: <Products />,
        label: "Retail Products",
        route: Routers?.products,
      },
      {
        icon: <Products />,
        label: "Takeaway Products",
        route: Routers?.takeawayproducts,
      },
      {
        icon: <Products />,
        label: "Takeaway Modifiers",
        route: Routers?.extratakeawayproducts,
      },
      // {
      //   icon: <Products />,
      //   label: "Synch Take Away Products",
      //   route: Routers?.synchTakeawayProduct,
      // },
      // {
      //   icon: <CirclePlus />,
      //   label: "Menu Manager",
      //   route: Routers?.menumanager,
      // },
    ],
  },
  {
    menu: "Sale",
    submenu: [
      {
        icon: <Orders />,
        label: "Orders",
        route: Routers?.orders,
      },
    ],
  },
  {
    menu: "Customer",
    submenu: [
      {
        icon: <Customers />,
        label: "Customers",
        route: Routers?.customer,
      },
    ],
  },
  {
    menu: "Promotions",
    submenu: [
      {
        icon: <Coupons />,
        label: "Coupons",
        route: Routers?.coupons,
      },
      {
        icon: <Communications />,
        label: "Communications",
        route: Routers?.communication,
      },
    ],
  },
  {
    menu: "Profiles",
    submenu: [
      {
        icon: <UserCircle />,
        label: "Driver",
        route: Routers?.drivers,
      },
      {
        icon: <UserCircle />,
        label: "Roaster Driver",
        route: Routers?.roasterdriver,
      },
      {
        icon: <UserCircle />,
        label: "In-store",
        route: Routers?.instore,
      },
      {
        icon: <UserCircle />,
        label: "Manager",
        route: Routers?.manager,
      },
    ],
  },

  {
    menu: "Chef Suite",
    submenu: [
      {
        icon: <CirclePlus />,
        label: "Recipes",
        route: Routers?.recipes,
      },
    ],
  },
  // {
  //   menu: "Eatery Suite",
  //   submenu: [
  //     {
  //       icon: <CirclePlus />,
  //       label: "Menu Manager",
  //       route: Routers?.menumanager,
  //     },
  //   ],
  // },
  {
    menu: "Memberships",
    submenu: [
      {
        icon: <CirclePlus />,
        label: "Membership",
        route: Routers?.memberShip,
      },
    ],
  },
  {
    menu: "Store Management",
    submenu: [
      {
        icon: <StoreManagement />,
        label: "Store Management",
        route: Routers?.store,
      },
      {
        icon: <Polygon />,
        label: "Polygon",
        route: Routers?.polygon,
      },
    ],
  },
  {
    menu: "Reports",
    submenu: [
      {
        icon: <CirclePlus />,
        label: "Reports",
        route: Routers?.salesReports,
      },
      {
        icon: <CirclePlus />,
        label: "General Overview Report",
        route: Routers?.generalOverviewReport,
      },
      {
        icon: <CirclePlus />,
        label: "Total Sales and Orders Report",
        route: Routers?.totalSalesOrdersReport,
      },
      {
        icon: <CirclePlus />,
        label: "Top-Selling Products by Category",
        route: Routers?.topSellingProductsCategory,
      },
      {
        icon: <CirclePlus />,
        label: "Sales by Product Category",
        route: Routers?.salesProductCategory,
      },
      {
        icon: <CirclePlus />,
        label: "Average Order Time Report",
        route: Routers?.averageOrderTimeReport,
      },
      {
        icon: <CirclePlus />,
        label: "Errors Per Order",
        route: Routers?.errorsPerOrder,
      },
      {
        icon: <CirclePlus />,
        label: "Hourly Sales Trends",
        route: Routers?.hourlySalesTrends,
      },
      {
        icon: <CirclePlus />,
        label: "Refund Cancellation",
        route: Routers?.refundCancellation,
      },
      {
        icon: <CirclePlus />,
        label: "Customer Report",
        route: Routers?.customerReport,
      },
      {
        icon: <CirclePlus />,
        label: "Out Of Stock",
        route: Routers?.outOfStock,
      },
    ],
  },
  {
    menu: "Reports",
    submenu: [
      {
        icon: <CirclePlus />,
        label: "WhiteList",
        route: Routers?.whiteList,
      },
    ],
  },

  // {
  //   menu: "WareHouse",
  //   submenu: [
  //     {
  //       icon: <StoreManagement />,
  //       label: "WareHouse",
  //       route: Routers?.warehouse,
  //     },
  //   ],
  // },
];

export { sidebar_menu };
