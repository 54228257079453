import { ApexOptions } from "apexcharts";

const columns_bestsellers = [
  {
    field: "product",
    label: "",
    type: "text",
  },
  {
    field: "price",
    label: "Price",
    type: "text",
  },

  {
    field: "sold",
    label: "Sold",
    type: "text",
  },
  {
    field: "profit",
    label: "Profit",
    type: "text",
  },
];

const data_bestsellers = [
  {
    id: "1",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "2",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "3",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "4",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "5",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
];

const columns_trending = [
  {
    field: "imageURL",
    label: "",
    type: "img",
  },
  {
    field: "itemName",
    label: "",
    type: "text",
  },
  {
    field: "sellingPriceInclPrice2",
    label: "",
    type: "text",
  },
];

const data_trending = [
  {
    id: "1",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "2",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "3",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "4",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
  {
    id: "5",
    product: "Item 1",
    price: "R 2150",
    sold: "409",
    profit: "R 1500.00",
  },
];

const getVisitor = (data: { date: string; count: number }[]) => {
  const categories = data.map((item) => item.date);
  const counts = data.map((item) => item.count);

  const sales_statics = {
    chart: {
      type: "column",
    },
    title: {
      text: "", // Empty title, adjust if needed
    },
    xAxis: {
      categories: categories,
      crosshair: false,
      accessibility: {
        description: "Dates", // Adjust description as needed
      },
    },
    yAxis: {
      gridLineColor: "transparent",
      min: 0,
      title: {
        text: ".", // Empty axis title, adjust if needed
      },
    },
    tooltip: {
      valueSuffix: "", // Adjust based on your data (e.g., units)
    },
    plotOptions: {
      column: {
        pointPadding: 0.4,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false, // Disable credits
    },
    series: [
      {
        name: "Visitor Count", // Adjust the series name if needed
        data: counts,
        color: "#54634B", // Column color
        showInLegend: false, // Hide legend
      },
    ],
  };

  return sales_statics;
};

export { getVisitor };

const linegraph = {
  chart: {
    renderTo: "container",
    type: "spline",
  },

  title: {
    text: "",
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    symbolWidth: 80,
  },
  tooltip: {
    crosshairs: false,
    shared: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      data: [2, 14, 1, 13, 4, 12],
      showInLegend: false,
      gridLineColor: "transparent",
      color: "#983939",
    },
  ],
};

const getLineGraphOptions = (data: any, color = "#983939") => {
  return {
    chart: {
      renderTo: "container",
      type: "spline",
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      symbolWidth: 80,
    },
    tooltip: {
      crosshairs: false,
      shared: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: data,
        showInLegend: false,
        gridLineColor: "transparent",
        color: color,
      },
    ],
  };
};

const getLineGraphOptionsSales = (category: any, data: any) => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: category,
      crosshair: false,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      gridLineColor: "transparent",

      min: 0,
      title: {
        text: ".",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.4,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: data,
        color: "#54634B",
        showInLegend: false,
      },
    ],
  };
};

const productStockDetails = [
  {
    field: "imageURL",
    label: "Image",
    type: "img",
  },
  {
    field: "itemName",
    label: "Product Name",
    type: "text",
  },
  {
    field: "sku",
    label: "SKU",
    type: "text",
  },

  {
    field: "salesPrice",
    label: "Price",
    type: "text",
  },
  {
    field: "sellQuantity",
    label: "Sell QTY",
    type: "text",
  },
  {
    field: "netProfit",
    label: "Profit",
    type: "text",
  },
];

const overviewReport = [
  {
    field: "metric",
    label: "Metric",
    type: "text",
  },
  {
    field: "daily",
    label: "Daily",
    type: "text",
  },
  {
    field: "currentWeek",
    label: "Current Week",
    type: "text",
  },

  {
    field: "currentMonth",
    label: "Current Month",
    type: "text",
  },
];

const generalOverivewReport = {
  series: [
    {
      name: "High - 2013",
      data: [28, 29, 33, 36, 32, 32, 33],
    },
    {
      name: "Low - 2013",
      data: [12, 11, 14, 18, 17, 13, 13],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.5,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Temperature",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  },
};

const gorOptions: ApexOptions = {
  chart: {
    type: "line",
    height: 350,
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    categories: ["Daily", "Current Week", "Current Month"],
  },
  yaxis: [
    {
      title: {
        text: "Price/Value",
      },
    },
  ],
  tooltip: {
    shared: true,
    intersect: false,
  },
};

// Utility function to clean and parse values
const parseCurrency = (value: string) =>
  parseFloat(value?.replace(/R\s?/g, ""));
const parsePercentage = (value: string) => parseFloat(value?.replace(/%/g, ""));

const gorSeries = (data: any[]) => [
  {
    name: "Total Sales (R)",
    data: [
      parseCurrency(data[0].daily),
      parseCurrency(data[0].currentWeek),
      parseCurrency(data[0].currentMonth),
    ],
  },
  {
    name: "Total Orders",
    data: [data[1].daily, data[1].currentWeek, data[1].currentMonth],
  },
  {
    name: "Average Order Value (R)",
    data: [
      parseCurrency(data[2].daily),
      parseCurrency(data[2].currentWeek),
      parseCurrency(data[2].currentMonth),
    ],
  },
  {
    name: "Errors per Order (%)",
    data: [
      parsePercentage(data[3].daily),
      parsePercentage(data[3].currentWeek),
      parsePercentage(data[3].currentMonth),
    ],
  },
];

const ordersSalesChartOptions = (totalSalesOrderReport: {
  categories: any[];
}): ApexOptions => {
  return {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%", // Adjust column width for better visibility
      },
    },
    xaxis: {
      categories: totalSalesOrderReport.categories,
    },
    yaxis: {
      title: {
        text: "Count / Sales",
      },
      labels: {
        formatter: (value: number) => {
          // Convert the value to a string after rounding
          return Math.round(value).toString(); // Ensure the value is returned as a string
        },
      },
    },
    legend: {
      position: "top",
    },
    // Customize the colors of the bars for better visibility
    colors: ["#1E90FF", "#32CD32"], // Blue for Total Orders and Green for Total Sales
    tooltip: {
      shared: true,
      intersect: false,
    },
    dataLabels: {
      enabled: false, // Disable data labels on top of bars
    },
  };
};

const ordersSalesChartSeries = (totalSalesOrderReport: {
  totalOrders: any;
  totalSales: any;
}) => [
  {
    name: "Total Orders",
    data: totalSalesOrderReport.totalOrders,
  },
  {
    name: "Total Sales",
    data: totalSalesOrderReport.totalSales,
  },
];

const averageOrderValueChartOptions = (totalSalesOrderReport: {
  categories: any[];
}): ApexOptions => {
  return {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: totalSalesOrderReport?.categories,
    },
    yaxis: {
      title: {
        text: "Average Order Value",
      },
      labels: {
        formatter: (value: number) => {
          return `R ${value.toFixed(2)}`; // Format AOV as currency
        },
      },
    },
    colors: ["#FF6347"], // Red for AOV
    markers: {
      size: 4,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };
};

const averageOrderValueChartSeries = (averageOrderValue: any[]) => [
  {
    name: "Average Order Value",
    data: averageOrderValue,
  },
];

const salesEachCategoryOptions = (
  salesEachCategoryCategories: any[]
): ApexOptions => {
  return {
    chart: {
      type: "bar", // Column chart type
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: salesEachCategoryCategories,
    },
    yaxis: {
      title: {
        text: "Total Sales",
      },
    },
  };
};

const salesEachCategorySeries = (salesEachCategorySales: any[]) => [
  {
    name: "Sales",
    data: salesEachCategorySales,
  },
];

const averageOrderTimeSeries = (data: any[]) => [
  {
    name: "Average Order Time (minutes)",
    data: data.map((item) => ({ x: item.date, y: item.avgDuration })),
  },
];

const averageOrderTimeOptions: ApexOptions = {
  chart: {
    type: "line",
    zoom: { enabled: false },
  },
  xaxis: {
    type: "datetime",
    title: { text: "Date" },
  },
  yaxis: {
    title: { text: "Average Time (minutes)" },
  },
  tooltip: {
    x: {
      format: "yyyy-MM-dd",
    },
  },
};

export {
  columns_bestsellers,
  data_bestsellers,
  columns_trending,
  data_trending,
  linegraph,
  getLineGraphOptions,
  getLineGraphOptionsSales,
  productStockDetails,
  overviewReport,
  generalOverivewReport,
  gorOptions,
  gorSeries,
  ordersSalesChartSeries,
  ordersSalesChartOptions,
  averageOrderValueChartOptions,
  averageOrderValueChartSeries,
  salesEachCategoryOptions,
  salesEachCategorySeries,
  averageOrderTimeSeries,
  averageOrderTimeOptions,
};
