import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import { selectedKeys } from "./data";
import ReactQuill from "react-quill";
import { useAlert } from "../ContextAPI/ContextApi";
import "react-toastify/dist/ReactToastify.css";
import { updateProduct } from "../../Api/Product/mutations";
import { handleFileUpload } from "../../Common Functions/Function";
import validationSchema from "./validation";
import { useFormik } from "formik";
import ImageLoader from "../../Effects/ImageLoader";
import FormEffect from "../../Effects/FormEffect";
import { TagsInput } from "react-tag-input-component";
import { ProductResponse } from "./utils";
import { searchWithPKSK } from "../../Api/Search/mutation";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

function UpdateExtraTakeAwayProducts() {
  const { PK, SK } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [productData, setproductData] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false);
  const [dataLoader, setdataLoader] = useState(false);
  const { storeId } = useStoreId();
  const [skuMatchProductData, setSkuMatchProductData] = useState<any>();
  const [formData, setFormData] = useState<any>({});
  const { alert, message, errorType } = useAlert();
  const [inventryProductDetails, setnventryProductDetails] = useState({
    architemName: "",
    archSku: "",
    archProductBrand: "",
    archProductSize: "",
    archCostPrice: "",
    archSalesPrice: "",
  });
  let index = process.env.REACT_APP_STACK
    ? `product${process.env.REACT_APP_STACK}`
    : "product";

  const formik = useFormik({
    initialValues: {
      itemName: "",
      sku: "",
      subcategoryId: "",
      categoryId: "",
      categorySK: "",
      subcategorySK: "",
      description: "",
      productBrand: "",
      productSize: "",
      itemStatus: true,
      salesPrice: "",
      packWeightGrams: "",
      packSizeInmm: "",
      localInternational: "",
      halaalKosher: "",
      vegetarianVegan: "",
      onlinePicture: false,
      glutenFree: false,
      alcoholic: false,
      imported: false,
      halaal: false,
      kosher: false,
      vegetarian: false,
      vegan: false,
      sugarFree: false,
      imageURL: "",
      costPrice: "",
      packSizeBreadth: "",
      packSizeHeight: "",
      packSizeLength: "",
      storeId: storeId,
      tags: [],
      entityName: index,
      productType: "EXTRATAKEAWAY",
      productCode: "",
      PK: "",
      SK: "",
      id: "",
      mealCategories: [],
      mealSubCategories: [],
      storeCategories: [],
      storeSubCategories: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setsaveLoader(true);
      const newData: FormData = {
        extraFieldsProduct: {},
      };

      for (const key in values) {
        // Ensure the key exists in values
        if (!Object.prototype.hasOwnProperty.call(values, key)) continue;

        // Avoid overriding already updated values
        if (selectedKeys.includes(key)) {
          if (!(key in newData.extraFieldsProduct)) {
            // Only update if not set before
            //@ts-expect-error
            newData.extraFieldsProduct[key] = values[key];
            console.log(
              "Updated key:",
              key,
              "Value:",
              newData.extraFieldsProduct[key]
            );
          }
        } else {
          if (!(key in newData)) {
            // Only update if not set before
            //@ts-expect-error
            newData[key] = values[key];
            console.log("Updated key:", key, "Value:", newData[key]);
          }
        }
      }

      const mergeObjects = (obj1: object, obj2: object) => {
        return { ...obj1, ...obj2 };
      };
      const mergedProductData = mergeObjects(
        formData,
        newData.extraFieldsProduct
      );
      const vals = JSON.stringify(mergedProductData);
      newData.mealCategories = [];
      newData.mealSubCategories = [];
      newData.mealCategories = [];
      newData.mealSubCategories = [];
      newData.extraFieldsProduct = vals;
      newData.productType = "EXTRATAKEAWAY";

      delete newData?.Takeaway;
      delete newData?.categoryId;
      delete newData?.categorySK;
      delete newData?.subcategorySK;
      delete newData?.subcategoryId;
      delete newData?.salesPrice;
      delete newData?.label;
      delete newData?.value;

      updateProduct(newData)
        .then((response) => {
          const result = JSON.parse(response.data.updateProduct);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            navigate(Routers?.extratakeawayproducts);
            setAlert(true);
            setErrorType("success");
            setMessage("Product Update Successfully");
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
          }
          setsaveLoader(false);
        })
        .catch((err) => {
          setsaveLoader(false);
          setAlert(true);
          setErrorType("error");
          setMessage(err?.errors[0].message);
        });
    },
  });

  interface FormData {
    [key: string]: any;
  }

  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await handleFileUpload(
      event,
      setLoader,
      formik.setFieldValue,
      setAlert,
      setErrorType,
      setMessage
    );
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const getProduct = useCallback(async () => {
    setdataLoader(true);
    await searchWithPKSK("product", PK, SK)
      .then(async (res) => {
        const result = ProductResponse(res);
        setproductData(result?.modifiedData[0]);
        interface StringIndexable {
          [key: string]: any;
        }

        const nonMatchingKeysObject: StringIndexable = Object.keys(
          result?.modifiedData[0]?.extraFieldsProduct
        )
          .filter((key) => !selectedKeys.includes(key))
          .reduce((obj: StringIndexable, key) => {
            const value = result?.modifiedData[0]?.extraFieldsProduct[key];
            if (
              typeof value !== "boolean" &&
              value !== "true" &&
              value !== "false"
            ) {
              obj[key] = value;
            }
            return obj;
          }, {});

        setSkuMatchProductData(nonMatchingKeysObject);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [PK, SK]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    // Ensure productData is available
    if (productData) {
      setdataLoader(false);
      const updateFormikValues = async () => {
        const modifiedData = productData || {}; // Ensure modifiedData exists
        const parsedVariants = modifiedData.extraFieldsProduct;

        // Loop through the selected keys
        for (const key of selectedKeys) {
          // Check if the key exists in extraFieldsProduct
          if (key === "productType") continue;

          if (modifiedData?.extraFieldsProduct?.hasOwnProperty(key)) {
            const value = modifiedData.extraFieldsProduct[key];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              `${key}`,
              typeof parsedValue === "boolean" ? parsedValue : value // Use original value if boolean
            );
          }

          // Check if the key exists at the root level
          if (modifiedData.hasOwnProperty(key)) {
            const value = modifiedData[key];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              key,
              typeof parsedValue === "boolean" ? parsedValue : value // Use original value if boolean
            );
          }
        }

        // Update any other fields present at the root level (outside selectedKeys)
        for (const rootKey in modifiedData) {
          // Ensure the root key is not in extraFieldsProduct or already set by the selectedKeys loop
          if (!selectedKeys.includes(rootKey)) {
            const value = modifiedData[rootKey];

            // Attempt to parse the value first
            let parsedValue;
            try {
              parsedValue = JSON.parse(value);
            } catch (e) {
              parsedValue = value; // Keep original value if parsing fails
            }

            // Check if the parsed value is a boolean
            await formik.setFieldValue(
              rootKey,
              typeof parsedValue === "boolean" ? parsedValue : value // Use original value if boolean
            );
          }
        }
        setnventryProductDetails({
          architemName: productData?.archItemName,
          archSku: productData?.productCode,
          archProductBrand: parsedVariants?.archProductBrand,
          archProductSize: parsedVariants?.archProductSize,
          archCostPrice: parsedVariants?.archSalesPrice,
          archSalesPrice: parsedVariants?.archSalesPrice,
        });
        setFormData(parsedVariants);
      };

      updateFormikValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  const handleSubmit = () => {
    if (storeId === "") {
      if (storeId === "") {
        setAlert(true);
        setMessage("First select a store");
        setErrorType("error");
        return;
      }
      return;
    }
    if (formik && formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  // const handleCategory = (data: any) => {
  //   let val = isValidJSON(data) ? JSON.parse(data) : data;
  //   formik.setFieldValue("categorySK", val.SK);
  //   formik.setFieldValue("categoryId", val.id);
  //   formik.setFieldValue("subcategorySK", "");
  //   formik.setFieldValue("subcategoryId", "");
  // };

  // const handleSubCategory = (data: any) => {
  //   let val = isValidJSON(data) ? JSON.parse(data) : data;
  //   formik.setFieldValue("subcategorySK", val.SK);
  //   formik.setFieldValue("subcategoryId", val.id);
  // };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const formatLabel = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  // eslint-disable-next-line
  const renderExtraFields = () => {
    const extraFields = skuMatchProductData || {};
    return Object.keys(extraFields).map((key) => (
      <div className="mb-3 col-md-4" key={key}>
        <label htmlFor={key} className="form-label">
          {formatLabel(key)}
        </label>
        <input
          type="text"
          className="form-control"
          id={key}
          name={key}
          placeholder={key}
          onChange={handleChange}
          value={formData[key]}
        />
      </div>
    ));
  };

  const handleBlur = (event: any, field: string) => {
    let value = event.target.value;
    const cleanedValue = value.replace(/[^\d.]/g, "");
    // const [integerPart, decimalPart] = cleanedValue.split(".");
    // if (decimalPart && decimalPart.length > 2) {
    //   value = `${integerPart}.${decimalPart.substring(0, 2)}`;
    // } else {
    //   value = cleanedValue;
    // }
    formik.setFieldValue(field, cleanedValue);
  };

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {loader && <ImageLoader />}
        {alert && message && (
          <Toast type={errorType ? errorType : "success"} message={message} />
        )}
        <LoadingOverlay isVisible={saveLoader} message="Please wait..." />

        <div className="form-top-sticky  d-flex align-items-center justify-content-between gap-2">
          <div className="heading-title mt-2 mt-md-0">
            <h4 className="theme_color fw-bolder">
              <Link
                className="text-decoration-none theme_color"
                to={`/${path}`}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {`Update ${formik?.values?.itemName}`}
            </h4>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Link className="text-decoration-none" to={`/${path}`}>
              <button className="btn btn-outline-danger" type="button">
                Cancel
              </button>
            </Link>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleSubmit}
              disabled={loader}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <div className="container">
            {dataLoader ? (
              <FormEffect />
            ) : (
              <div className="row">
                <div className="col-md-8">
                  <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                    <h4 className="theme_color fw-bold align-self-center mb-3">
                      {"Inventory Product Properties"}
                    </h4>
                    <div className="row">
                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className={`form-label`}
                        >
                          Product Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          value={inventryProductDetails?.architemName}
                        />
                      </div>
                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className={`form-label`}
                        >
                          Product Code<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          value={inventryProductDetails?.archSku}
                        />
                      </div>
                      <div className={`form-group row mb-3`}>
                        <div className="mb-3 col-md-6">
                          <label
                            htmlFor="productBrand"
                            className={`form-label`}
                          >
                            Product Brand
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={inventryProductDetails?.archProductBrand}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="productSize" className={`form-label`}>
                            Product Size
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={inventryProductDetails?.archProductSize}
                          />
                        </div>
                      </div>
                      <div className={`form-group row mb-3`}>
                        <div className="mb-3 col-md-6">
                          <label
                            htmlFor="archCostPrice"
                            className={`form-label`}
                          >
                            Cost Price<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={inventryProductDetails?.archCostPrice}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label
                            htmlFor="archSalesPrice"
                            className={`form-label`}
                          >
                            Sales Price<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={inventryProductDetails?.archSalesPrice}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                    <h4 className="theme_color fw-bold align-self-center mb-3">
                      {"App Product Properties"}
                    </h4>
                    {/* <div className={`form-group col-12 mb-3`}>
                      <Select
                        cacheOptions={true}
                        defaultOptions={List}
                        onChange={(val: any) =>
                          formik.setFieldValue("productType", val.id)
                        }
                        onBlur={formik.handleBlur}
                        data={List}
                        value={List.find(
                          (item: { id: string }) =>
                            item?.id === formik?.values.productType,
                        )}
                      />
                    </div> */}
                    <div className="row">
                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className={`form-label`}
                        >
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="itemName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          value={formik?.values?.itemName}
                        />
                        {formik.touched.itemName && formik?.errors?.itemName ? (
                          <div className="text-danger">
                            {formik?.errors?.itemName}
                          </div>
                        ) : null}
                      </div>
                      <div className={`form-group col-12 mb-3`}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className={`form-label`}
                        >
                          Product Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="sku"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          value={formik?.values?.sku}
                          readOnly
                        />
                        {formik.touched.sku && formik?.errors?.sku ? (
                          <div className="text-danger">
                            {formik?.errors?.sku}
                          </div>
                        ) : null}
                      </div>

                      <div className={`form-group row mb-3`}>
                        <div className="mb-3 col-md-6">
                          <label
                            htmlFor="productBrand"
                            className={`form-label`}
                          >
                            Product Brand
                            {/*  */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="productBrand"
                            name="productBrand"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            value={formik?.values?.productBrand}
                          />
                          {formik.touched.productBrand &&
                          formik?.errors?.productBrand ? (
                            <div className="text-danger">
                              {formik?.errors?.productBrand}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="productSize" className={`form-label`}>
                            Product Size
                            {/*  */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="productSize"
                            name="productSize"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            min={0}
                            value={formik?.values?.productSize}
                          />
                          {formik.touched.productSize &&
                          formik?.errors?.productSize ? (
                            <div className="text-danger">
                              {formik?.errors?.productSize}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className={`form-group row mb-3`}>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="costPrice" className={`form-label`}>
                            Cost Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="costPrice"
                            name="costPrice"
                            onChange={formik.handleChange}
                            onKeyUp={(e) => handleBlur(e, "costPrice")}
                            required
                            min={0}
                            value={formik?.values?.costPrice}
                          />
                          {formik.touched.costPrice &&
                          formik?.errors?.costPrice ? (
                            <div className="text-danger">
                              {formik?.errors?.costPrice}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="salesPrice" className={`form-label`}>
                            Sales Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="salesPrice"
                            name="salesPrice"
                            min={0}
                            onChange={formik.handleChange}
                            onKeyUp={(e) => handleBlur(e, "salesPrice")}
                            required
                            value={formik?.values?.salesPrice}
                          />
                          {formik.touched.salesPrice &&
                          formik?.errors?.salesPrice ? (
                            <div className="text-danger">
                              {formik?.errors?.salesPrice}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={`form-group col-12 mb-3`}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Description
                        </label>
                        <ReactQuill
                          theme="snow"
                          onChange={(content, delta, source, editor) => {
                            formik.setFieldValue(
                              "description",
                              editor.getHTML()
                            );
                          }}
                          value={formik.values.description}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6] }],
                              ["bold", "italic", "link"],
                              [{ list: "ordered" }],
                              ["blockquote"],
                            ],
                          }}
                        />
                        {formik.touched.description &&
                        formik?.errors?.description ? (
                          <div className="text-danger">
                            {formik?.errors?.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="description1" className={`form-label`}>
                          Category
                        </label>

                        <Select
                          cacheOptions={true}
                          onChange={handleCategory}
                          defaultOptions={parentItems}
                          data={parentItems}
                          value={parentItems.find(
                            (item: { SK: string }) =>
                              item?.SK === formik?.values.categorySK,
                          )}
                        />

                        {formik.touched.categoryId &&
                        formik?.errors?.categoryId ? (
                          <div className="text-danger">
                            {formik?.errors?.categoryId}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="description2" className={`form-label`}>
                          Sub-Category
                        </label>

                        <Select
                          onChange={handleSubCategory}
                          defaultOptions={subItems}
                          data={subItems}
                          value={
                            formik?.values?.subcategorySK === ""
                              ? null
                              : subItems.find(
                                  (item: { SK: string }) =>
                                    item?.SK === formik?.values?.subcategorySK,
                                )
                          }
                        />
                        {formik.touched.subcategoryId &&
                        formik?.errors?.subcategoryId ? (
                          <div className="text-danger">
                            {formik?.errors?.subcategoryId}
                          </div>
                        ) : null}
                      </div>
                    </div> */}

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Tags
                      </label>

                      <div className="row">
                        <div className="form-group col-12">
                          <TagsInput
                            name="tags"
                            onBlur={formik.handleBlur}
                            onChange={(tags) =>
                              formik.handleChange({
                                target: { name: "tags", value: tags },
                              })
                            }
                            value={formik?.values?.tags}
                          />
                          <small className="form-text text-muted">
                            <i className="bi bi-info-circle"></i> Press Enter
                            after typing your tag to add it.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <div className="mb-3 col-md-12">
                        <label
                          htmlFor="packWeightGrams"
                          className={`form-label`}
                        >
                          Pack Weight Grams
                          {/*  */}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="packWeightGrams"
                            name="packWeightGrams"
                            min={0}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik?.values?.packWeightGrams}
                          />
                          {/* <span className="input-group-text">ZAR</span> */}
                        </div>
                        {formik.touched.packWeightGrams &&
                        formik?.errors?.packWeightGrams ? (
                          <div className="text-danger">
                            {formik?.errors?.packWeightGrams}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <div className="mb-3 col-md-12">
                        <label htmlFor="packSizeInmm" className={`form-label `}>
                          Pack Size in mm
                        </label>
                        <div className="row">
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeLength"
                              className={`form-label`}
                            >
                              Length
                              {/*  */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="packSizeLength"
                              name="packSizeLength"
                              placeholder="Length"
                              onChange={formik.handleChange}
                              onKeyUp={(e) => handleBlur(e, "packSizeLength")}
                              required
                              min={0}
                              value={formik?.values?.packSizeLength}
                            />
                            {formik.touched.packSizeLength &&
                            formik?.errors?.packSizeLength ? (
                              <div className="text-danger">
                                {formik?.errors?.packSizeLength}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeBreadth"
                              className={`form-label`}
                            >
                              Breadth
                              {/*  */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="packSizeBreadth"
                              name="packSizeBreadth"
                              placeholder="Breadth"
                              min={0}
                              onChange={formik.handleChange}
                              onKeyUp={(e) => handleBlur(e, "packSizeBreadth")}
                              value={formik?.values?.packSizeBreadth}
                              required
                            />
                            {formik.touched.packSizeBreadth &&
                            formik?.errors?.packSizeBreadth ? (
                              <div className="text-danger">
                                {formik?.errors?.packSizeBreadth}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label
                              htmlFor="packSizeHeight"
                              className={`form-label`}
                            >
                              Height
                              {/*  */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="packSizeHeight"
                              name="packSizeHeight"
                              placeholder="Height"
                              min={0}
                              onChange={formik.handleChange}
                              onKeyUp={(e) => handleBlur(e, "packSizeHeight")}
                              value={formik?.values?.packSizeHeight}
                              required
                            />
                            {formik.touched.packSizeHeight &&
                            formik?.errors?.packSizeHeight ? (
                              <div className="text-danger">
                                {formik?.errors?.packSizeHeight}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={`form-group row mb-3`}>
                      <div className="mb-3 col-md-6">
                        <label
                          htmlFor="localInternational"
                          className={`form-label`}
                        >
                          Local/International
                        </label>
                        <select
                          name="localInternational"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          value={formik?.values?.localInternational}
                        >
                          <option value="">Select...</option>
                          <option value={1}>Local</option>
                          <option value={2}>International</option>
                        </select>
                        {formik.touched.localInternational &&
                        formik?.errors?.localInternational ? (
                          <div className="text-danger">
                            {formik?.errors?.localInternational}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="halaalKosher" className={`form-label`}>
                          Halaal/Kosher
                        </label>
                        <select
                          name="halaalKosher"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          value={formik?.values?.halaalKosher}
                        >
                          <option value="">Select...</option>
                          <option value={1}>Halaal</option>
                          <option value={2}>Kosher</option>
                        </select>
                        {formik.touched.halaalKosher &&
                        formik?.errors?.halaalKosher ? (
                          <div className="text-danger">
                            {formik?.errors?.halaalKosher}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={`form-group col-12 mb-3`}>
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className={`form-label`}
                      >
                        Vegetarian/Vegan
                      </label>
                      <select
                        name="vegetarianVegan"
                        className="form-select"
                        aria-label="Default select example"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                        value={formik?.values?.vegetarianVegan}
                      >
                        <option value="">Select...</option>
                        <option value={1}>Vegetarian</option>
                        <option value={2}>Vegan</option>
                      </select>
                      {formik.touched.vegetarianVegan &&
                      formik?.errors?.vegetarianVegan ? (
                        <div className="text-danger">
                          {formik?.errors?.vegetarianVegan}
                        </div>
                      ) : null}
                    </div> */}

                    <div className="row">
                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="imported"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.imported}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">Imported</label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="halaal"
                            onChange={(e) => {
                              formik.handleChange(e); // Calls the formik change handler
                            }}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.halaal}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">Halaal</label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="kosher"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.kosher}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">Kosher</label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="glutenFree"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.glutenFree}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">
                            Gluten Free
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="vegetarian"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.vegetarian}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">
                            Vegetarian
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="vegan"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.vegan}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">Vegan</label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="sugarFree"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.sugarFree}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">
                            Sugar-Free
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="alcoholic"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik?.values?.alcoholic}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label className="form-label p-0 m-0">
                            Contains Alcohol
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {skuMatchProductData && formData && (
                    <div className="border border-grey p-3 rounded mt-2 mt-md-4 white_bg">
                      <h4 className="theme_color fw-bold align-self-center mb-3">
                        {"ARCH Additional Fields"}
                      </h4>
                      <div className="row">{renderExtraFields()}</div>
                    </div>
                  )} */}
                </div>
                <div className="col-md-4">
                  <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      {"Product Banner"}
                    </h4>
                    <div className="new_category_banner_inner">
                      <div className="new_category_banner_content">
                        <div>
                          <i className="bi bi-arrow-up-circle upload_icon"></i>
                        </div>
                        <div>
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*,audio/*,video/*"
                            style={{ display: "none" }}
                            onChange={handleFileInputChangeAndUpload}
                            name="imageURL"
                          />
                          <button
                            type="button"
                            className="btn btn-outline-success"
                            onClick={handleButtonClick}
                          >
                            Add Image
                          </button>
                        </div>
                        <div>
                          <span className="upload_img_text">
                            {"Click to upload an image"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik?.values?.imageURL && (
                    <div className="border  border-grey  p-3 rounded mt-4 white_bg">
                      <h4 className="theme_color fw-bold mb-3 align-self-center">
                        {"Preview Image"}
                      </h4>
                      <div className="new_category_banner_inner d-block">
                        <div className="new_category_banner_content h-100 p-2">
                          <div className="image-container-preview">
                            <img
                              src={formik?.values?.imageURL}
                              alt="img"
                              className="image-preview"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="border border-grey p-3 rounded mt-4 white_bg">
                    <h4 className="theme_color fw-bold mb-3 align-self-center">
                      ARCH Status
                    </h4>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="itemStatus"
                        id="flexRadioDefault1"
                        value="disabled"
                        checked={!formik.values.itemStatus}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "itemStatus",
                            !formik.values.itemStatus
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Disabled
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="flexRadioDefault2"
                        value="enabled"
                        checked={formik.values.itemStatus}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "itemStatus",
                            !formik.values.itemStatus
                          );
                        }}
                      />
                      <label
                        className="form-check-label theme_color"
                        htmlFor="flexRadioDefault2"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateExtraTakeAwayProducts;
